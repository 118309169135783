body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.slider-container,
.short-slider-container {
  position: relative;
  width: 1080px;
  height: 1920px;
  overflow: hidden;
  margin: auto;
  border: 5px solid #ddd;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  zoom: 0.81;
  background-color: black;
  margin-top: 200px;
  margin-bottom: 200px;
}

.slider-content,
.short-slider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  width: 90%;
  white-space: pre-wrap;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}

.ayah,
.ayah-short {
  margin: 10px 0;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  text-align: center;
  padding: 10px 50px;
  color: rgb(237, 217, 105);
  text-shadow: 2px 2px 2px brown;
}

.DoneFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-fade-out {
  animation: fadeOut 5s forwards;
}

.video-fade-in {
  animation: fadeIn 10s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.audio-controls {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  color: black;
  zoom: 2;
  height: 100vh;
}

.audio-controls label {
  display: block;
  margin-bottom: 5px;
}

.audio-controls select,
.audio-controls input[type="number"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

.audio-controls input[type="checkbox"] {
  margin-right: 10px;
}

.reciter-selector,
.language-selector,
.edition-selector,
.start-ayah-selector,
.verse-limit-selector,
.autoplay-next-ayah-selector {
  margin-bottom: 10px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.video.video-visible {
  opacity: 1;
}

.slider-video {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.video-enter {
  opacity: 1;
}

/* Classes to apply the fade-in and fade-out animations */
.fade-in {
  animation: fadeIn 5s forwards;
}

.fade-out {
  animation: fadeOut 5s forwards;
}
/* this should work right now */