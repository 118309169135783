/* here is the new code  */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  zoom: 0.65;
  margin: 0;
  font-family: Arial, sans-serif;
  transition: all ease 10s;
}

:where(audio) {
  display: block;
  width: 617px;
  margin-top: 20px;
  height: 25px;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.css-2zwihz {
  zoom: 100%;
}

/* Add this to your existing stylesheet or create a new one */
.css-16xknkz {
  display: flex;
  align-content: center;
}

#root {
  height: 100%;
  zoom: 75%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  background-color: #f8f8f8;
  /* Set a background color for the body */
}

.box-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ancient_page {
  text-align: center;
  /* border-radius: 8px; */
  padding: 16px;
  box-shadow: 0px 0px 2px 1px gold;
  background-image: url("./images/transparent-background.jpg");
  /*Set the path to your ancient page image */
  background-size: cover;
  /* color: black; */
  margin-bottom: 20px;
}

.Modern_page {
  text-align: center;
  /* border-radius: 8px; */
  padding: 16px;
  box-shadow: 0px 0px 2px 1px gold;
  background-size: cover;
  /* color: white; */
  background-image: url("./images/image.jpg");
  /*Set the path to your ancient page image */
  margin-bottom: 20px;
}

.box h1 {
  margin: 0;
  font-size: 24px;
}

.box hr {
  border: 1px solid #ddd;
  width: 50%;
  margin: 16px auto;
}

.box p {
  font-size: 18px;
}

#myMenu {
  zoom: 75%;
}

@media (max-width: 768px) {
  #root {
    /* styles for smaller screens */
    zoom: 60%;
  }
}

@media (max-width: 641px) {
  #root {
    /* styles for smaller screens */
    zoom: 40%;
  }
}

@media (max-width: 523px) {
  #root {
    /* styles for smaller screens */
    zoom: 30%;
    min-width: 426px;
  }
}

.button {
  background-color: red;
}

.slider-video {
  width: 1920px;
  height: 1080px;
  object-fit: cover;
}