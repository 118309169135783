.vid-container {
  position: relative;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-color: black;
  transition: opacity 1s ease-in-out; /* Add transition for opacity */
}

.vid-container.hidden {
  opacity: 0;
}

.vid-container.visible {
  opacity: 1;
}

.ayah-container {
  transition: opacity 1s ease-in-out; /* Add transition for text */
}

.ayah-container.hidden {
  opacity: 0;
}

.ayah-container.visible {
  opacity: 1;
}

.ayah {
  padding: 10px 50px;
  color: rgb(237, 217, 105);
  text-shadow: 2px 2px 2px brown;
}

.vid-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  color: white;
  z-index: 10;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 2rem;
}

.vid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aud-controls {
  position: fixed;
  bottom: 50%;
  left: 9%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  zoom: 1;
}

.rec-selector,
.lang-selector,
.ed-selector,
.start-ayah-selector,
.autoplay-next-ayah-selector {
  margin-bottom: 10px;
}

.rec-selector label,
.lang-selector label,
.ed-selector label,
.start-ayah-selector label,
.autoplay-next-ayah-selector label {
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
  font-size: 32px;
}

.start-ayah-selector input,
.verse-limit-selector input {
  width: 250px;
  padding: 5px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.start-ayah-selector label,
.verse-limit-selector label {
  font-size: 40px;
  color: white;
}

.DoneFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
